import React, { forwardRef } from "react";
import "./Button.scss";

interface ButtonProps {
  theme?: "primary" | "secondary" | "error" | "warning" | "success";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
  href?: string;
  type?: "button" | "submit" | "reset";
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ theme = "primary", onClick, children, className, disabled = false, ariaLabel, href, type }, ref) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!disabled) {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
        if (href) {
          window.open(href, "_blank", "noopener noreferrer");
        }
      }
    };

    return (
      <button
        ref={ref}
        className={`button ${theme} ${className || ""} ${disabled ? "disabled" : ""}`}
        disabled={disabled}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (!disabled && (e.key === "Enter" || e.key === " ")) {
            e.preventDefault();
            onClick?.(e as unknown as React.MouseEvent<HTMLButtonElement>);
          }
        }}
        aria-label={ariaLabel}
        tabIndex={0}
        type={type}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
