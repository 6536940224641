// src/api/AuthorsArtistsAPI.ts
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
import { AuthorArtist } from "../types";

// Fetch all authors and artists from Firestore
export const fetchAuthorsAndArtists = async (): Promise<{
  authors: AuthorArtist[];
  artists: AuthorArtist[];
}> => {
  const authorCollection = collection(db, "authors");
  const artistCollection = collection(db, "artists");

  const [authorSnapshot, artistSnapshot] = await Promise.all([
    getDocs(authorCollection),
    getDocs(artistCollection),
  ]);

  const authors = authorSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as AuthorArtist[];
  const artists = artistSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as AuthorArtist[];

  return {
    authors: authors.sort((a, b) => a.name.localeCompare(b.name)),
    artists: artists.sort((a, b) => a.name.localeCompare(b.name)),
  };
};

export const addAuthor = async (author: AuthorArtist): Promise<void> => {
  try {
    await addDoc(collection(db, "authors"), author);
  } catch (error) {
    console.error("Error adding new author:", error);
  }
};

// Add a new artist
export const addArtist = async (artist: AuthorArtist): Promise<void> => {
  try {
    await addDoc(collection(db, "artists"), artist);
  } catch (error) {
    console.error("Error adding new artist:", error);
  }
};

export const updateAuthorArtist = async (id: string, updatedData: Partial<AuthorArtist>, type: "authors" | "artists"): Promise<void> => {
  try {
    const docRef = doc(db, type, id);
    await updateDoc(docRef, updatedData);
  } catch (error) {
    console.error(`Error updating ${type.slice(0, -1)}:`, error);
  }
};

export const deleteAuthorArtist = async (id: string, type: "authors" | "artists"): Promise<void> => {
  try {
    const docRef = doc(db, type, id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error(`Error deleting ${type.slice(0, -1)}:`, error);
  }
};
