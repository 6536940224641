// src/api/ImagesAPI.ts
import { collection, getDocs, deleteDoc, doc, updateDoc, addDoc } from "firebase/firestore";
import { ref, deleteObject, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../firebase";
import { GalleryImage } from "../types";

// Fetch all images from Firestore
export const fetchImages = async (): Promise<GalleryImage[]> => {
  try {
    const imageCollection = collection(db, "galleryImages");
    const imageSnapshot = await getDocs(imageCollection);
    return imageSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as GalleryImage[];
  } catch (error) {
    console.error("Error fetching images:", error);
    throw error;
  }
};

// Publish image by moving it from drafts to public
export const publishImage = async (image: GalleryImage): Promise<GalleryImage> => {
  try {
    const { id, imageUrl } = image;
    const fullPath = decodeURIComponent(imageUrl.split('/').pop()?.split('?')[0] || "");
    const fileName = fullPath.split('/').pop();
    const publicStorageRef = ref(storage, `images/${fileName}`);

    const response = await fetch(imageUrl);
    const blob = await response.blob();
    await uploadBytesResumable(publicStorageRef, blob);
    const publicURL = await getDownloadURL(publicStorageRef);

    await updateDoc(doc(db, "galleryImages", id), { imageUrl: publicURL, status: "published" });
    await deleteObject(ref(storage, `drafts/${fileName}`));
    return { ...image, imageUrl: publicURL, status: "published" };
  } catch (error) {
    console.error("Error publishing image:", error);
    throw error;
  }
};

// Add image data to Firestore document
export const addImageDocument = async (data: File) => {
  try {
    const docRef = await addDoc(collection(db, "galleryImages"), data);
    return { id: docRef.id, ...data };
  } catch (error) {
    console.error("Error adding image document:", error);
    throw error;
  }
};

// Unpublish image by moving it back to drafts
export const unpublishImage = async (image: GalleryImage): Promise<GalleryImage> => {
  try {
    const { id, imageUrl } = image;
    const fullPath = decodeURIComponent(imageUrl.split('/').pop()?.split('?')[0] || "");
    const fileName = fullPath.split('/').pop();
    const draftStorageRef = ref(storage, `drafts/${fileName}`);

    const response = await fetch(imageUrl);
    const blob = await response.blob();
    await uploadBytesResumable(draftStorageRef, blob);
    const draftURL = await getDownloadURL(draftStorageRef);

    await updateDoc(doc(db, "galleryImages", id), { imageUrl: draftURL, status: "draft" });
    await deleteObject(ref(storage, `images/${fileName}`));
    return { ...image, imageUrl: draftURL, status: "draft" };
  } catch (error) {
    console.error("Error unpublishing image:", error);
    throw error;
  }
};

// Delete image
export const deleteImage = async (image: GalleryImage): Promise<void> => {
  try {
    const imageRef = ref(storage, image.imageUrl);
    await deleteObject(imageRef);
    await deleteDoc(doc(db, "galleryImages", image.id));
  } catch (error) {
    console.error("Error deleting image:", error);
    throw error;
  }
};
