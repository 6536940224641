import React, { useState, ComponentType, InputHTMLAttributes } from "react";
import "./IconTextInput.scss";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface IconTextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: ComponentType;
  placeholder?: string;
  password?: boolean;
  className?: string;
  readOnly?: boolean;
  ariaLabel?: string;
  ariaDescription?: string;
  theme?: "primary" | "secondary" | "error" | "success" | "warning";
  disabled?: boolean;
  autocomplete?: boolean;
}

const IconTextInput: React.FC<IconTextInputProps> = ({
  icon: Icon,
  placeholder = "Enter Text",
  password,
  className,
  readOnly = false,
  ariaLabel,
  ariaDescription,
  theme,
  disabled,
  autocomplete = false,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  return (
    <div className={`icon-text-box ${theme || ""} ${className || ""} ${disabled ? "disabled" : ""}`}>
      {Icon && (
        <div className="icon-container" aria-hidden="true">
          <Icon />
        </div>
      )}
      <input
        type={password && !showPassword ? "password" : "text"}
        className={`text-input ${theme || ""}`}
        placeholder={placeholder}
        aria-label={ariaLabel || placeholder}
        aria-describedby={ariaDescription ? "input-description" : undefined}
        autoComplete={autocomplete ? "on" : "off"}
        readOnly={readOnly}
        disabled={disabled}
        {...props}
      />
      {ariaDescription && (
        <span id="input-description" className="sr-only">
          {ariaDescription}
        </span>
      )}
    </div>
  );
};

export default IconTextInput;
