import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import "./ImageViewbox.scss";

interface ViewBoxProps {
  imageSrc: string;
  author?: string;
  authorLink?: string;
  artist?: string;
  artistLink?: string;
  onClose: () => void;
}

const ViewBox: React.FC<ViewBoxProps> = ({
  imageSrc,
  author,
  authorLink,
  artist,
  artistLink,
  onClose,
}) => {
  const [, setIsOpen] = useState(false);

  useEffect(() => {
    if (imageSrc) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [imageSrc]);

  if (!imageSrc) return null;

  return (
    <Modal closeModal={onClose} className={"image-view-box"}>
      <div className="viewbox-header">
      </div>
      <div className="viewbox-image">
        <img src={imageSrc} alt="Full view" />
      </div>
      <div className="viewbox-footer">
        {author && (
          <p>
            Image by:{" "}
            {authorLink ? (
              <a href={authorLink} target="_blank" rel="noopener noreferrer">
                {author}
              </a>
            ) : (
              <span>{author}</span>
            )}
          </p>
        )}
        {artist && (
          <p>
            Artist:{" "}
            {artistLink ? (
              <a href={artistLink} target="_blank" rel="noopener noreferrer">
                {artist}
              </a>
            ) : (
              <span>{artist}</span>
            )}
          </p>
        )}
      </div>
    </Modal>
  );
};

export default ViewBox;
