export const firebaseErrorMessages = {
  "Firebase: Error (auth/invalid-credential).": "The username/password provided are invalid. Please try again.",
  "auth/user-not-found": "No user found with this email. Please check your email and try again.",
  "auth/wrong-password": "Incorrect password. Please try again.",
  default: "An unexpected error occurred. Please try again later.",
};

export function getErrorMessage(errorCode) {
  return firebaseErrorMessages[errorCode] || firebaseErrorMessages["default"];
}
