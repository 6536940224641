import React from "react";
import { motion } from "framer-motion";
import "./SkeletonLoader.scss";

const SkeletonLoader = ({ width, height }) => {
  return (
    <motion.div
      className="skeleton-loader"
      style={{
        width: width || "100%",
        height: height || "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.3 } }}
      transition={{ duration: 0.5, ease: "easeInOut" }} 
    ></motion.div>
  );
};

export default SkeletonLoader;
