import React, { useState } from 'react';
import { useAuth } from '../../Utils/Auth/AuthContext';
import Button from '../../Components/UI-Elements/Button/Button';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../Utils/firebaseErrors';
import './Login.scss';
import IconTextInput from '../../Components/UI-Elements/IconTextInput/IconTextInput';
import { FaKey, FaUser } from 'react-icons/fa';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loader state
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      await login(email, password);
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
      setError(getErrorMessage(error.message));
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = email.trim() !== '' && password.trim() !== '';

  return (
    <div className="login-page">
      <div className="login-container">
        <h1 className="login-title">Login</h1>
        
        <form onSubmit={handleSubmit} className="login-form" noValidate>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <IconTextInput
              type="email"
              id="email"
              className='login-text-input'
              value={email}
              icon={FaUser}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-required="true"
              aria-label="Email"
              aria-invalid={error ? 'true' : 'false'}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <IconTextInput
              icon={FaKey}
              type="password"
              className='login-text-input'
              id="password"
              password={true}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-required="true"
              aria-label="Password"
              aria-invalid={error ? 'true' : 'false'}
            />
          </div>
          
          {error && <p className="login-error" role="alert">{error}</p>}
          
          <Button 
            type="submit" 
            className="login-button" 
            disabled={loading || !isFormValid}
            onClick={handleSubmit}
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
