import { useState } from "react";
import { addImageDocument } from "../../APIs/ImagesAPI";
import { uploadImageToDrafts } from "../../APIs/storageAPI";
import { addAuthor, addArtist} from "../../APIs/AuthorsArtistsAPI";
import Select from "../UI-Elements/Select/Select";
import Button from "../UI-Elements/Button/Button";
import ProgressBar from "../UI-Elements/ProgressBar/ProgressBar";
import Accordion from "../UI-Elements/Accordion/Accordion";
import "./imageUpload.scss";
import IconTextInput from "../UI-Elements/IconTextInput/IconTextInput";

const ImageUpload = ({ authors, artists, setImages, images, loadAuthorsAndArtists }) => {
  const [imageFile, setImageFile] = useState(null);
  const [author, setAuthor] = useState("");
  const [authorLink, setAuthorLink] = useState("");
  const [artist, setArtist] = useState("");
  const [artistLink, setArtistLink] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const fileSizeLimit = 5 * 1024 * 1024;

  const resetForm = () => {
    setImageFile(null);
    setAuthor("");
    setAuthorLink("");
    setArtist("");
    setArtistLink("");
    setUploadProgress(0);
    setError("");
  };

  const handleImageUpload = async () => {
    setError(null);
    setSuccessMessage("");
  
    if (!navigator.onLine) return setError("You are offline. Please check your internet connection.");
    if (!imageFile) return setError("Please select an image.");
    if (imageFile.size > fileSizeLimit) return setError("File size exceeds the 5MB limit.");
    if (!["image/jpeg", "image/png", "image/jpg"].includes(imageFile.type)) return setError("Invalid file type. Please upload a JPEG, JPG or PNG");
    if (!author || !artist) return setError("Please ensure all required fields are filled.");
  
    try {
      let authorAdded = false;
      let artistAdded = false;

      // Add new author if not existing
      const existingAuthor = authors.find((a) => a.name === author);
      if (!existingAuthor) {
        await addAuthor({ name: author, link: authorLink });
        authorAdded = true;
      }

      // Add new artist if not existing
      const existingArtist = artists.find((a) => a.name === artist);
      if (!existingArtist) {
        await addArtist({ name: artist, link: artistLink });
        artistAdded = true;
      }

      // Refetch authors and artists if new entries were added
      if (authorAdded || artistAdded) {
        await loadAuthorsAndArtists();
      }

      // Proceed with image upload
      const { downloadURL } = await uploadImageToDrafts(imageFile);
      const imageDoc = await addImageDocument({
        imageUrl: downloadURL,
        author,
        authorLink,
        artist,
        artistLink,
        status: "draft",
      });

      setImages([...images, imageDoc]);
      setSuccessMessage("Image uploaded to drafts successfully!");
      resetForm();
    } catch (error) {
      setError("An error occurred during upload. Please try again.");
      console.error("Upload failed", error);
    }
  };

  const handleAuthorChange = (value) => {
    const selectedAuthor = authors.find((author) => author.name === value);
    setAuthor(selectedAuthor ? selectedAuthor.name : value);
    setAuthorLink(selectedAuthor ? selectedAuthor.link : "");
  };

  const handleArtistChange = (value) => {
    const selectedArtist = artists.find((artist) => artist.name === value);
    setArtist(selectedArtist ? selectedArtist.name : value);
    setArtistLink(selectedArtist ? selectedArtist.link : "");
  };

  return (
    <div className="image-upload">
      <Accordion title="Add New Image" theme="primary">
        <form>
          <div className="form-group">
            <input type="file" onChange={(e) => setImageFile(e.target.files[0])} className="file-input" />
          </div>
          <div className="form-group">
            <Select
              label="Select Existing Author"
              options={authors?.map((author) => ({ value: author.name.toString(), label: author.name }))}
              value={author}
              onChange={handleAuthorChange}
            />
            <IconTextInput type="text" placeholder="New Author Name" value={author} onChange={(e) => setAuthor(e.target.value)} />
          </div>
          <div className="form-group">
            <IconTextInput type="text" placeholder="Author Link (e.g., Instagram URL)" value={authorLink} onChange={(e) => setAuthorLink(e.target.value)} />
          </div>
          <div className="form-group">
            <Select
              label="Select Existing Artist"
              options={artists?.map((artist) => ({ value: artist.name.toString(), label: artist.name }))}
              value={artist}
              onChange={handleArtistChange}
            />
            <IconTextInput type="text" placeholder="New Artist Name" value={artist} onChange={(e) => setArtist(e.target.value)} />
          </div>
          <div className="form-group">
            <IconTextInput type="text" placeholder="Artist Link (e.g., Website URL)" value={artistLink} onChange={(e) => setArtistLink(e.target.value)} />
          </div>
          {!errorMessage && <Button onClick={handleImageUpload}>Upload Image</Button>}
          {errorMessage && <Button onClick={handleImageUpload}>Retry Upload</Button>}
          {uploadProgress > 0 && <ProgressBar progress={uploadProgress} />}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
        </form>
      </Accordion>
    </div>
  );
};

export default ImageUpload;
