import React, { useState, useEffect } from "react";
import {
  fetchImages,
  publishImage,
  unpublishImage,
  deleteImage,
} from "../../APIs/ImagesAPI";
import { fetchAuthorsAndArtists, updateAuthorArtist, deleteAuthorArtist } from "../../APIs/AuthorsArtistsAPI";
import { GalleryImage, VideoData, AuthorArtist } from "../../types";
import {
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import ImageUpload from "../../Components/imageUpload/imageUpload";
//import YouTubeLinkUpload from "../../Components/YoutubeLinkUpload/YoutubeLinkUpload";
//import EditYouTubeVideoModal from "../../Components/UI-Elements/Modal/EditYoutubeModal/EditYoutubeModal";
import EditImageModal from "../../Components/UI-Elements/Modal/EditImageModal/EditImageModal";
import Button from "../../Components/UI-Elements/Button/Button";
import "./DashboardPage.scss";
import Pager from "../../Components/UI-Elements/Pager/Pager";
import IconTextInput from "../../Components/UI-Elements/IconTextInput/IconTextInput";
import { FaSearch } from "react-icons/fa";
import Select from "../../Components/UI-Elements/Select/Select";
import EditAutArtModal from "../../Components/UI-Elements/Modal/EditAutArtModal/EditAuthArtModal";


const statusFilterOptions = [
  { value: "all", label: "All" },
  { value: "published", label: "Published" },
  { value: "draft", label: "Draft" },
];

const DashboardPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"images" | "youtubeVideos">("images");

  const [authors, setAuthors] = useState<AuthorArtist[]>([]);
  const [artists, setArtists] = useState<AuthorArtist[]>([]);
  const [images, setImages] = useState<GalleryImage[]>([]);
  //const [videos, setVideos] = useState<VideoData[]>([]);

  const [selectedImage, setSelectedImage] = useState<GalleryImage | null>(null);
  const [, setSelectedVideo] = useState<VideoData | null>(null);
  const [selectedAuthorArtist, setSelectedAuthorArtist] = useState<AuthorArtist | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setIsVideoModalOpen] = useState(false);
  const [isAuthorArtistModalOpen, setIsAuthorArtistModalOpen] = useState(false)

  const [author, setAuthor] = useState("");
  const [authorLink, setAuthorLink] = useState("");
  const [artist, setArtist] = useState("");
  const [artistLink, setArtistLink] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState<"all" | "published" | "draft">("all");
  const itemsPerPage = 6;

  const loadAuthorsAndArtists = async () => {
    const { authors, artists } = await fetchAuthorsAndArtists();
    setAuthors(authors);
    setArtists(artists);
  };

  useEffect(() => {
    const loadImages = async () => {
      const images = await fetchImages();
      setImages(images);
    };

    /*
    const fetchVideos = async () => {
      const videoCollection = collection(db, "galleryYoutubeVideos");
      const videoSnapshot = await getDocs(videoCollection);
      const videoList = videoSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as VideoData[];
      setVideos(videoList);
    };
    */

    loadImages();
    loadAuthorsAndArtists();
  }, []);

  const handleImageClick = (image: GalleryImage) => {
    setSelectedImage(image);
    setAuthor(image.author || "");
    setAuthorLink(image.authorLink || "");
    setArtist(image.artist || "");
    setArtistLink(image.artistLink || "");
    setIsModalOpen(true);
  };

  const handlePublishImage = async (image: GalleryImage) => {
    try {
      const updatedImage = await publishImage(image);
      setImages((prevImages) => prevImages.map((img) =>
        img.id === updatedImage.id ? updatedImage : img
      ));
    } catch (e) {
      console.error("Error publishing image:", e);
      alert("Failed to publish image, please try again.");
    }
    closeModal();
  };

  const handleUnpublishImage = async (image: GalleryImage) => {
    try {
      const updatedImage = await unpublishImage(image);
      setImages((prevImages) => prevImages.map((img) =>
        img.id === updatedImage.id ? updatedImage : img
      ));
    } catch (e) {
      console.error("Error unpublishing image:", e);
      alert("Failed to unpublish image, please try again.");
    }
    closeModal();
  };

  const handleDeleteImage = async (image: GalleryImage) => {
    if (window.confirm("Are you sure you want to delete this image? This action cannot be undone.")) {
      await deleteImage(image);
      setImages((prevImages) => prevImages.filter((img) => img.id !== image.id));
      setIsModalOpen(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const filteredImages = images.filter((image) => {
    const matchesStatus = statusFilter === "all" || image.status === statusFilter;
    const matchesQuery =
      image.author?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      image.artist?.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesStatus && matchesQuery;
  });

  const handlePageChange = (page: number) => setCurrentPage(page);

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  /*
  const handleVideoClick = (video: VideoData) => {
    setSelectedVideo(video);
    setIsVideoModalOpen(true);
  };

  const handleDeleteYoutubeVideo = async () => {
    if (selectedVideo && window.confirm("Are you sure you want to delete this video?")) {
      try {
        const docRef = doc(db, "galleryYoutubeVideos", selectedVideo.id);
        await deleteDoc(docRef);

        setVideos((prevVideos) => prevVideos.filter((video) => video.id !== selectedVideo.id));
        closeModal();
      } catch (error) {
        console.error("Error deleting video:", error);
      }
    }
  };

  const handleSaveVideoChanges = async (updatedVideo: Partial<VideoData>) => {
    if (selectedVideo) {
      try {
        const docRef = doc(db, "galleryYoutubeVideos", selectedVideo.id);
        await updateDoc(docRef, updatedVideo);

        setVideos(
          videos.map((video) =>
            video.id === selectedVideo.id ? { ...video, ...updatedVideo } : video
          )
        );
        closeModal();
      } catch (error) {
        console.error("Error updating video:", error);
      }
    }
  };
  */

  const handleSaveChanges = async (updatedImage: Partial<GalleryImage>) => {
    if (selectedImage) {
      try {
        const docRef = doc(db, "galleryImages", selectedImage.id);
        await updateDoc(docRef, updatedImage);

        setImages(
          images.map((image) =>
            image.id === selectedImage.id ? { ...image, ...updatedImage } : image
          )
        );
        closeModal();
      } catch (error) {
        console.error("Error updating image:", error);
      }
    }
  };

  const handleAuthorChange = (value: string | number) => {
    const selectedAuthor = authors.find((author) => author.name === value);
    if (selectedAuthor) {
      setAuthor(selectedAuthor.name);
      setAuthorLink(selectedAuthor.link || "");
    } else {
      setAuthor(String(value));
      setAuthorLink("");
    }
  };

  const handleArtistChange = (value: string | number) => {
    const selectedArtist = artists.find((artist) => artist.name === value);
    if (selectedArtist) {
      setArtist(selectedArtist.name);
      setArtistLink(selectedArtist.link || "");
    } else {
      setArtist(String(value));
      setArtistLink("");
    }
  };

  const handleAuthorArtistSelect = (value: string | number) => {
    const selected = [...authors, ...artists].find((item) => item.name === value);
    if (selected) {
      setSelectedAuthorArtist(selected);
      setIsAuthorArtistModalOpen(true);
    }
  };

  const handleAuthorArtistSave = async (updatedData: Partial<AuthorArtist>) => {
    if (selectedAuthorArtist) {
      const type = authors.some((author) => author.id === selectedAuthorArtist.id) ? "authors" : "artists";
      await updateAuthorArtist(selectedAuthorArtist.id, updatedData, type);
      loadAuthorsAndArtists();
      setIsAuthorArtistModalOpen(false);
      setSelectedAuthorArtist(null);
    }
  };

  const handleAuthorArtistDelete = async () => {
    if (selectedAuthorArtist) {
      const type = authors.some((author) => author.id === selectedAuthorArtist.id) ? "authors" : "artists";
      
      if (window.confirm(`Are you sure you want to delete this ${type.slice(0, -1)}? This action cannot be undone.`)) {
        try {
          await deleteAuthorArtist(selectedAuthorArtist.id, type);
          loadAuthorsAndArtists(); // Refresh the list of authors and artists after deletion
          setIsAuthorArtistModalOpen(false);
          setSelectedAuthorArtist(null);
        } catch (error) {
          console.error("Error deleting author/artist:", error);
          alert("Failed to delete the author or artist. Please try again.");
        }
      }
    }
  };



  const closeModal = () => {
    setIsModalOpen(false);
    setIsVideoModalOpen(false);
    setIsAuthorArtistModalOpen(false);
    setSelectedAuthorArtist(null);
    setSelectedImage(null);
    setSelectedVideo(null);
    setAuthor("");
    setAuthorLink("");
    setArtist("");
    setArtistLink("");
  };

  return (
    <div className="dashboard-page">
      <header className="dashboard-header">
        <h1>Admin Dashboard</h1>
        <p>Manage gallery images and videos.</p>
      </header>
      <div className="tabs">
        <Button
          onClick={() => setActiveTab("images")}
          theme={activeTab === "images" ? "primary" : "secondary"}
        >
          Images
        </Button>
        <Button
          onClick={() => setActiveTab("youtubeVideos")}
          theme={activeTab === "youtubeVideos" ? "primary" : "secondary"}
        >
          YouTube Videos
        </Button>
      </div>

      {activeTab === "images" && (
        <>
          <section className="dashboard-section">
            <div className="author-artist-editor">
              <h3>Edit Saved Authors and Artists</h3>
              <Select
                options={[...authors, ...artists].map(({ name }) => ({
                  label: name,
                  value: name,
                }))}
                placeholder="Select Author/Artist"
                onChange={handleAuthorArtistSelect}
              />
            </div>
          </section>
          <section className="dashboard-section">
            <ImageUpload
              authors={authors}
              artists={artists}
              setImages={setImages}
              images={images}
              loadAuthorsAndArtists={loadAuthorsAndArtists}
            />
            <div className="image-gallery">
              <h3>Gallery Images</h3>
              <IconTextInput
                icon={FaSearch}
                placeholder="Search by author or artist..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="dashboard-search-input"
              />
              <div className="filter-controls">
                <p>Filter by Status: </p>
                <Select
                  options={statusFilterOptions}
                  value={statusFilter}
                  onChange={(value) => setStatusFilter(value as "all" | "published" | "draft")}
                  className="status-filter"
                  placeholder="Select Status"
                />
              </div>
              {filteredImages.length > 0 ? (
              <>
              <Pager
                totalItems={filteredImages.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
              <ul className="image-gallery-items">
                {paginatedImages.map((image, index) => (
                  <li key={image.id} onClick={() => handleImageClick(image)}>
                    <img src={image.imageUrl} alt={`Gallery ${index + 1}`} />
                  </li>
                ))}
              </ul>
              <Pager
                totalItems={filteredImages.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
              </> ) : (
              <h2 className="no-images-message">No images available</h2>
               )}
            </div>
          </section>
        </>
      )}

      {activeTab === "youtubeVideos" && (
        <section className="dashboard-section">
          <div className="under-construction">
            <h1>🚧 Under Construction 🚧</h1>
            <p>This section is under construction</p>
          </div>
          {/*
          <YouTubeLinkUpload setVideos={setVideos} />
          <div className="video-gallery">
            <h3>YouTube Videos</h3>
            <ul className="video-gallery-items">
              {videos.map((video, index) => (
                <li
                  className="youtubeGalleryItem"
                  key={video.videoName}
                  onClick={() => handleVideoClick(video)}
                >
                  <iframe
                    width="1280"
                    height="720"
                    src={video.videoUrl}
                    title="Featured Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </li>
              ))}
            </ul>
          </div>
          */}
        </section>
      )}

      {isModalOpen && selectedImage && (
        <EditImageModal
          selectedImage={selectedImage}
          authors={authors}
          artists={artists}
          author={author}
          authorLink={authorLink}
          artist={artist}
          artistLink={artistLink}
          setAuthor={setAuthor}
          setAuthorLink={setAuthorLink}
          setArtist={setArtist}
          setArtistLink={setArtistLink}
          handleAuthorChange={handleAuthorChange}
          handleArtistChange={handleArtistChange}
          handleSaveChanges={handleSaveChanges}
          handlePublishImage={() => handlePublishImage(selectedImage)}
          handleUnpublishImage={() => handleUnpublishImage(selectedImage)}
          handleDeleteImage={() => handleDeleteImage(selectedImage)}
          closeModal={closeModal}
        />
      )}

      {isAuthorArtistModalOpen && selectedAuthorArtist && (
        <EditAutArtModal
        authorArtist={selectedAuthorArtist}
        setName={(name: any) => setSelectedAuthorArtist((prev) => (prev ? { ...prev, name } : prev))}
        setLink={(link: any) => setSelectedAuthorArtist((prev) => (prev ? { ...prev, link } : prev))}
        handleSaveChanges={handleAuthorArtistSave}
        closeModal={closeModal}
        handleDelete={handleAuthorArtistDelete}
      />
      )}

      {/*
      {isVideoModalOpen && (
        <EditYouTubeVideoModal
          selectedVideo={selectedVideo}
          handleSaveChanges={handleSaveVideoChanges}
          handleDeleteVideo={handleDeleteYoutubeVideo}
          closeModal={closeModal}
        />
      )}
      */}
    </div>
  );
};

export default DashboardPage;
