import React, { useState, ReactNode } from "react";
import "./Accordion.scss";

interface AccordionProps {
  title: string;
  children: ReactNode;
  theme?: "primary" | "secondary" | "error" | "success" | "warning" | "clear";
  initiallyExpanded?: boolean;
  className?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  theme,
  initiallyExpanded = false,
  className = "",
}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);

  const toggleAccordion = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={`custom-accordion ${theme} ${className}`}>
      <button
        className={`custom-accordion__header ${theme}`}
        onClick={toggleAccordion}
        type="button"
        aria-controls={`accordion-content-${title}`}
      >
        <span className="custom-accordion__title">{title}</span>
        <span
          className={`custom-accordion__icon ${isExpanded ? "expanded" : ""}`}
          aria-hidden="true"
        >
          {isExpanded ? "-" : "+"}
        </span>
      </button>
      <div
        id={`accordion-content-${title}`}
        className={`custom-accordion__content ${isExpanded ? "expanded" : ""} ${theme}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
