import React, { useState, useEffect } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import ViewBox from "../../Components/UI-Elements/Modal/ImageViewBox/ImageViewbox";
import SkeletonLoader from "../../Components/UI-Elements/SkeletonLoader/SkeletonLoader";
import Pager from "../../Components/UI-Elements/Pager/Pager";
import "./GalleryPage.scss";
import { motion } from "framer-motion";
import IconTextInput from "../../Components/UI-Elements/IconTextInput/IconTextInput";
import { FaSearch } from "react-icons/fa";

const GalleryPage = () => {
  const [images, setImages] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [, setImageLoaded] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [author, setAuthor] = useState("");
  const [authorLink, setAuthorLink] = useState("");
  const [artist, setArtist] = useState("");
  const [artistLink, setArtistLink] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 18;

  useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      const storageRef = ref(storage, "images/");
      const result = await listAll(storageRef);

      const imageCollection = collection(db, "galleryImages");
      const imageSnapshot = await getDocs(imageCollection);
      const imageMetaData = imageSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const urls = await Promise.all(
        result.items.map(async (item) => {
          const url = await getDownloadURL(item);
          const metadata = imageMetaData.find((meta) => meta.imageUrl === url);
          return {
            url,
            author: metadata ? metadata.author : "",
            authorLink: metadata ? metadata.authorLink : "",
            artist: metadata ? metadata.artist : "",
            artistLink: metadata ? metadata.artistLink : "",
          };
        })
      );

      setImages(urls);
      setFilteredImages(urls);
      setLoading(false);
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      setImageLoaded(Array(images.length).fill(false));
    }
  }, [images]);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setCurrentPage(1);

    const filtered = images.filter(
      (image) =>
        image.author.toLowerCase().includes(query) ||
        image.artist.toLowerCase().includes(query)
    );
    setFilteredImages(filtered);
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const paginatedImages = filteredImages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleImageClick = (image) => {
    setSelectedImage(image.url);
    setAuthor(image.author);
    setAuthorLink(image.authorLink);
    setArtist(image.artist);
    setArtistLink(image.artistLink);
  };

  const closeViewBox = () => {
    setSelectedImage(null);
    setAuthor("");
    setAuthorLink("");
    setArtist("");
    setArtistLink("");
  };

  return (
    <>
      <motion.div
        className="page-container"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{
          opacity: 0,
          x: 100,
          transition: { duration: 0.2, ease: "easeOut" },
        }}
      >
        <div className="gallery-container">
          <header className="gallery-header">
            <h1>Event Gallery</h1>
            <p>Explore moments captured from our past events.</p>
          </header>

          <div className="search-bar">
            <IconTextInput
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-input"
              theme="primary"
              icon={FaSearch}
            />
          </div>
          {filteredImages.length > 0 ? (
            <>
              <Pager
                totalItems={filteredImages.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
              <div className="gallery-grid">
                {loading
                  ? Array.from({ length: itemsPerPage }).map((_, index) => (
                      <div key={index} className="gallery-item">
                        <SkeletonLoader width="100%" height="200px" />
                      </div>
                    ))
                  : paginatedImages.map((image, index) => (
                      <div
                        key={index}
                        className="gallery-item"
                        onClick={() => handleImageClick(image)}
                      >
                        <img
                          src={image.url}
                          alt={`Event ${index + 1}`}
                          loading="lazy"
                        />
                      </div>
                    ))}
              </div>

              <Pager
                totalItems={filteredImages.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </>
          ) : (
            <div className="no-image-container">
            <h2 className="no-images-message">No images available</h2>
            </div>
          )}
        </div>
      </motion.div>

      {selectedImage && (
        <ViewBox
          imageSrc={selectedImage}
          author={author}
          authorLink={authorLink}
          artist={artist}
          artistLink={artistLink}
          onClose={closeViewBox}
        />
      )}
    </>
  );
};

export default GalleryPage;
