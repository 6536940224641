import React from "react";
import Select from "../../Select/Select";
import Modal from "../Modal";
import "./EditImageModal.scss";
import IconTextInput from "../../IconTextInput/IconTextInput";

interface GalleryImage {
  id: string;
  imageUrl: string;
  author?: string;
  authorLink?: string;
  artist?: string;
  artistLink?: string;
  status?: "draft" | "published";
}

interface WidgetControl {
  id: number;
  label: string;
  theme?: "primary" | "secondary" | "error" | "warning" | "success";
  onClick: () => void;
}

interface EditImageModalProps {
  selectedImage: GalleryImage | null;
  authors: { name: string; link?: string }[];
  artists: { name: string; link?: string }[];
  author: string;
  authorLink: string;
  artist: string;
  artistLink: string;
  setAuthor: (value: string) => void;
  setAuthorLink: (value: string) => void;
  setArtist: (value: string) => void;
  setArtistLink: (value: string) => void;
  handleAuthorChange: (value: string | number) => void;
  handleArtistChange: (value: string | number) => void;
  handleSaveChanges: (data: {
    author: string;
    authorLink: string;
    artist: string;
    artistLink: string;
  }) => void;
  handleDeleteImage: () => void;
  handlePublishImage: () => void;
  handleUnpublishImage: () => void;
  closeModal: () => void;
}

const EditImageModal: React.FC<EditImageModalProps> = ({
  selectedImage,
  authors,
  artists,
  author,
  authorLink,
  artist,
  artistLink,
  setAuthor,
  setAuthorLink,
  setArtist,
  setArtistLink,
  handleAuthorChange,
  handleArtistChange,
  handleSaveChanges,
  handleDeleteImage,
  handlePublishImage,
  handleUnpublishImage,
  closeModal,
}) => {
  const modalControls: WidgetControl[] = [
    {
      id: 1,
      label: "Save Changes",
      onClick: () =>
        handleSaveChanges({
          author,
          authorLink,
          artist,
          artistLink,
        }),
      theme: "success",
    },
    {
      id: 2,
      label: selectedImage?.status === "published" ? "Unpublish Image" : "Publish Image",
      onClick: selectedImage?.status === "published" ? handleUnpublishImage : handlePublishImage,
      theme: "warning",
    },
    {
      id: 3,
      label: "Delete Image",
      onClick: handleDeleteImage,
      theme: "error",
    },
    {
      id: 4,
      label: "Cancel",
      onClick: closeModal,
      theme: "secondary",
    },
  ];

  return (
    <Modal title="Edit Image Details" closeModal={closeModal} modalControls={modalControls}>
      <div className="form-group">
        <Select
          title="Select Existing Author"
          options={authors.map((author) => ({
            value: author.name,
            label: author.name,
          }))}
          value={author}
          onChange={handleAuthorChange}
        />
        <IconTextInput
          type="text"
          placeholder="Author Name"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
        />
      </div>
      <div className="form-group">
        <IconTextInput
          type="text"
          placeholder="Author Link"
          value={authorLink}
          onChange={(e) => setAuthorLink(e.target.value)}
        />
      </div>
      <div className="form-group">
        <Select
          title="Select Existing Artist"
          options={artists.map((artist) => ({
            value: artist.name,
            label: artist.name,
          }))}
          value={artist}
          onChange={handleArtistChange}
        />
        <IconTextInput
          type="text"
          placeholder="Artist Name"
          value={artist}
          onChange={(e) => setArtist(e.target.value)}
        />
      </div>
      <div className="form-group">
        <IconTextInput
          type="text"
          placeholder="Artist Link"
          value={artistLink}
          onChange={(e) => setArtistLink(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default EditImageModal;
