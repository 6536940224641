import React from "react";
import Modal from "../Modal";
import IconTextInput from "../../IconTextInput/IconTextInput";
import "./EditAutArtModal.scss";

interface AuthorArtist {
  id: string;
  name: string;
  link?: string;
}

interface WidgetControl {
  id: number;
  label: string;
  theme?: "primary" | "secondary" | "error" | "warning" | "success";
  onClick: () => void;
}

interface EditAuthorArtistModalProps {
  authorArtist: AuthorArtist;
  setName: (value: string) => void;
  setLink: (value: string) => void;
  handleSaveChanges: (data: { name: string; link: string }) => void;
  handleDelete: () => void;
  closeModal: () => void;
}

const EditAutArtModal: React.FC<EditAuthorArtistModalProps> = ({
  authorArtist,
  setName,
  setLink,
  handleSaveChanges,
  handleDelete,
  closeModal,
}) => {
  const modalControls: WidgetControl[] = [
    {
      id: 1,
      label: "Save Changes",
      onClick: () =>
        handleSaveChanges({
          name: authorArtist.name,
          link: authorArtist.link || "",
        }),
      theme: "success",
    },
    {
      id: 2,
      label: "Delete",
      onClick: handleDelete,
      theme: "error",
    },
    {
      id: 3,
      label: "Cancel",
      onClick: closeModal,
      theme: "secondary",
    },
  ];

  return (
    <Modal title="Edit Author/Artist Details" closeModal={closeModal} modalControls={modalControls}>
      <div className="form-group">
        <IconTextInput
          type="text"
          placeholder="Name"
          value={authorArtist.name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <IconTextInput
          type="text"
          placeholder="Link"
          value={authorArtist.link || ""}
          onChange={(e) => setLink(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default EditAutArtModal;
