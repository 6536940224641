import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/UI-Elements/Button/Button';
import "./PageNotFound.scss";
import { FaDog } from 'react-icons/fa';
import { motion } from 'framer-motion';

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Takes the user to the previous page
  };

  const handleGoHome = () => {
    navigate('/'); // Navigates the user to the homepage
  };

  return (
    <>
      <motion.div
        className="page-container"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{
          opacity: 0,
          x: 100,
          transition: { duration: 0.2, ease: "easeOut" },
        }}
      >
        <div className="notFound-outline">
          <h1 className="notFound-heading">404 - Page Not Found</h1>
          <FaDog aria-label="Dog icon" className='dog-icon' />
          <p className="notFound-message">
          The dogs ate this page, or it might have been moved. Use the options below to find your way.
          </p>
          <div className="notFound-buttons">
            <Button onClick={handleGoBack} variant="primary">
              Go Back
            </Button>
            <Button onClick={handleGoHome} variant="secondary">
              Go to Homepage
            </Button>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default PageNotFound;
