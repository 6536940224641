import React from 'react';
import './InfoPage.scss';

import { motion } from 'framer-motion';

const InfoPage = () => {
  return (
    <motion.div className="info-page"
    initial={{ opacity: 0, x: -100 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 100, transition: { duration: 0.2, ease: "easeOut" } }}>
      <header className="info-header">
        <h1>About Us</h1>
        <p>
          Welcome to Dog Pit, a space where creativity and community come together.
        </p>
        <p>Our venue is built by musicians, for musicians, offering a platform to showcase talent and connect with like-minded individuals. Whether you're a performer or a fan, Dog Pit is the place to experience live music in an intimate and supportive environment.</p>
      </header>
      
      <section className="tickets-section">
        <h1>Tickets</h1>
        <p>
          You can purchase tickets at the door or online through our <a href='https://www.eventbrite.ca/o/rare-candyz-77027845793' target='_blank' rel='noopener noreferrer'>Eventbrite</a> page. If you need a refund for a digital ticket, you can request it directly through Eventbrite, or if you purchased a ticket at the door, please contact us on Instagram. We're here to help make your experience as smooth as possible.
        </p>
      </section>
      
      <section className="rules-section">
        <h1>Venue Policies</h1>
        <p>
          To ensure a safe and enjoyable experience for everyone, please adhere to the following policies:
        </p>
        <ul>
          <li>We are a 19+ event space. Please bring valid ID.</li>
          <li>No glassware is allowed inside the venue for safety reasons.</li>
          <li>Our location is private. Please do not share the address publicly. If someone needs it, they can ask a punk.</li>
          <li>Street parking is available, but please be considerate and avoid parking in neighboring driveways.</li>
          <li>Quiet hours begin at 11pm. We kindly ask all attendees to respect this and keep noise to a minimum outside the venue.</li>
          <li>After the event, please do not loiter. Let's respect our neighbors and the community.</li>
          <li>Respect the space, the artists, and each other. We're all here to enjoy and celebrate music together.</li>
        </ul>
      </section>
      
      <section className="technical-section">
        <h1>Backline and Technical Setup</h1>
        <p>
          Our venue is equipped with a full backline to support a wide range of performances. Here’s what we offer:
        </p>
        <ul>
          <li>16-input Europower PMX 3000 mixer, perfect for managing diverse audio setups.</li>
          <li>1 Passive DI for clean and noise-free instrument signals.</li>
          <li>2 Power bars.</li>
          <li>2 Audience loudspeakers and 2 artist monitors.</li>
          <li>1 Powered subwoofer.</li>
          <li>House drum kit: 2 toms (1 rack, 1 floor), 3 cymbal stands, hi-hat stand, snare stand—bring your own snare, kick pedal and cymbals</li>
          <li>3 Microphone boom stands and 2 Sennheiser live performance microphones.</li>
          <li>XLR snake with up to 7 female XLR inputs on stage.</li>
          <li>LED stage lighting system with customizable color options.</li>
        </ul>
        <p>
          If you have any special technical requirements, please let us know in advance, and we'll do our best to accommodate your needs.
        </p>
      </section>
    </motion.div>
  );
};

export default InfoPage;
