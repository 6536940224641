import { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../Logo.svg";
import { formatDate } from "../../Utils/formatter";

const useFetchEvents = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          `https://www.eventbriteapi.com/v3/organizations/${process.env.REACT_APP_EVENTBRITE_ID}/events/?status=live`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_EVENTBRITE_API_KEY}`,
            },
          }
        );
        const eventsList = response.data.events.map((event) => ({
          id: event.id,
          title: event.name.text,
          date: event.start.local,
          formattedDate: formatDate(event.start.local),
          ticketLink: event.url,
          displayImage: event.logo ? event.logo.url : logo,
        }));
        const upcomingEvents = eventsList.filter(event => new Date(event.date) > new Date());
        const nextEvent = upcomingEvents.sort((a, b) => new Date(a.date) - new Date(b.date))[0];

        setEvents(eventsList);
        if (nextEvent) {
          setSelectedEvent(nextEvent);
        }
      } catch (error) {
        console.error("Error fetching events from Eventbrite: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  return { events, selectedEvent, setSelectedEvent, loading };
};

export default useFetchEvents;
